<template>
  <div class="box">
    <img src="@/assets/logo_regular.svg" alt="">
    <h3>Bald Online! Wir freuen uns auf deine Bewerbung!</h3>
    <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
    <router-link class="lms-main-button" to="/" tag="button">Zurück</router-link>
  </div>
</template>

<script>
export default {
  name: 'WorkInProgress',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  @import '@/style/general.scss';

  .box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }

  h3 {
    font-family: $avenir;
    font-weight: 400;
    color: #000000;
    opacity: 0.8;
    margin: 40px 40px 0px 40px;
    text-align: center;
  }

  img {
    height: 45px;
  }

  .lms-main-button {
    margin: 100px 0 0 0;
  }

  // Spinner 
  .lds-ellipsis {
    display: inline-block;
    position: relative;
    width: 75px;
    height: 30px;
    margin: 20px 0 0 0;
  }

  .lds-ellipsis div {
    position: absolute;
    top: 10px;
    width: 10px;
    height: 10px;
    border-radius: 3px;
    background: $lumos-pink;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }

  .lds-ellipsis div:nth-child(1) {
    left: 8px;
    animation: lds-ellipsis1 0.6s infinite;
  }

  .lds-ellipsis div:nth-child(2) {
    left: 8px;
    animation: lds-ellipsis2 0.6s infinite;
  }

  .lds-ellipsis div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
  }

  .lds-ellipsis div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
  }

  @keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }

  @keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }

  @keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(24px, 0);
    }
  }

</style>
